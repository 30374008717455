import { action, makeObservable, observable } from "mobx";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { ModalStore } from "../common";
import { FormStore } from "../common/form.store";
import { AuthForgotRequest, AuthSignUpRequest } from "../../requests";
import { MethodRequest } from "../../constants";
import { AuthSignResponse, CoreResponse, FacebookClientResponsive } from "../../response";
import { UserStore } from "../user/user.store";
import { UserI } from "../../interfaces";

export class AuthStore {
	protected PATH = "auth";

	@observable isLoading = false;
	@observable isAuth = false;
	@observable userStore = new UserStore(null);

	@observable signInForm = new FormStore();
	@observable signUpForm = new FormStore();
	@observable forgotForm = new FormStore();

	@observable congratulationsModal = new ModalStore();
	@observable congratulationsEmail = "";
	@observable successForgot = new ModalStore();

	constructor(initialData: AuthStore | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setCongratulationsEmail(value: string) {
		this.congratulationsEmail = value;
	}

	@action.bound
	setIsAuth(value: boolean) {
		this.isAuth = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	async changePassword(values: any) {
		this.setIsLoading(true);

		try {
			await API.request<CoreResponse<any>>(`auth/change-password`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});
		} catch (e) {
			ErrorAPI("changePassword", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async signFacebook(values: FacebookClientResponsive) {
		this.setIsLoading(true);

		try {
			const { access_token, data } = await API.request<AuthSignResponse>(`auth/sign-in-facebook`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});

			// Заполняем модель пользователя
			this.userStore.fillStore(data);
			// Записываем токен
			API.saveToken = access_token;
			// Авторизовываем пользователя
			this.setIsAuth(true);
		} catch (e) {
			ErrorAPI("signFacebook", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async signGoogle(values: any) {
		this.setIsLoading(true);
		try {
			const { access_token, data } = await API.request<AuthSignResponse>(`auth/sign-in-google`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});

			// Заполняем модель пользователя
			this.userStore.fillStore(data);
			// Записываем токен
			API.saveToken = access_token;
			// Авторизовываем пользователя
			this.setIsAuth(true);
		} catch (e) {
			ErrorAPI("signGoogle", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async signIn(values: AuthSignUpRequest) {
		this.setIsLoading(true);
		this.signInForm.setIsLoading(true);

		try {
			const { access_token, data } = await API.request<AuthSignResponse>(`${this.PATH}/sign-in`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});

			// Заполняем модель пользователя
			this.userStore.fillStore(data);

			// Записываем токен
			API.saveToken = access_token;
			// Авторизовываем пользователя
			this.setIsAuth(true);
		} catch (e: unknown) {
			ErrorAPI("signIn", e);
		} finally {
			this.signInForm.setIsLoading(false);
			this.setIsLoading(false);
		}
	}

	@action.bound
	async signUp(values: AuthSignUpRequest) {
		this.signUpForm.setIsLoading(true);

		try {
			const { access_token, data } = await API.request<AuthSignResponse>(`${this.PATH}/sign-up`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});

			// Записываем токен
			API.saveToken = access_token;

			// Заполняем модель пользователя
			this.userStore.fillStore(data);

			// Авторизовываем пользователя
			this.setIsAuth(true);
		} catch (e) {
			ErrorAPI("signUp", e);
		} finally {
			this.signUpForm.setIsLoading(false);
		}
	}

	@action.bound
	async forgotPassword(values: AuthForgotRequest) {
		this.forgotForm.setIsLoading(true);

		try {
			await API.request(`${this.PATH}/reset-password`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});
		} catch (e) {
			ErrorAPI("forgotPassword", e);
		} finally {
			this.forgotForm.setIsLoading(false);
		}
	}

	@action.bound
	async check() {
		try {
			if (!API.jwtToken.length) {
				return;
			}

			const { data } = await API.request<CoreResponse<UserI>>(`${this.PATH}/check`);

			// Заполняем пользователя
			this.userStore.fillStore(data);
			// Пользоатель авторизован
			this.setIsAuth(true);
		} catch (e) {
			// ErrorAPI("check", e);
		}
	}

	@action.bound
	signOut() {
		API.removeToken();
		this.setIsAuth(false);
	}

	@action
	fillStore(data: AuthStore) {
		const { isAuth } = data;

		this.isAuth = isAuth;
	}
}
